<template>
    <div v-if="loadingState.value === 'loading'">Loading</div>
    <div v-else id="video"></div>
</template>
<script>
    import {watchEffect, ref} from 'vue'
    import {useRoute, useRouter} from 'vue-router'
    import axios from 'axios'
    // import { ref } from '@vue/composition-api'

    export default {
        setup() {
            const route = useRoute()
            const router = useRouter()
            const otp = ref(null) // use const because you dont want to lose this value
            const loadingState = ref('loading')
            const videoId = route.params.id;
            const fetchOtp = () => {
                loadingState.value = 'loading'
                return axios.get('https://api.kibanovadolls.ru/video/info/'+videoId)
                    .then(response => {
                        loadingState.value = 'success'
                        otp.value = response.data.data.otp
                    })
                    .catch(error => {
                        //todo add error view
                        if (error.response.status === 404) {
                            router.push("/404")
                        }
                        loadingState.value = 'error'
                    })
            }
            watchEffect(() => {
                if (otp.value === null || otp.value === undefined) {
                    return
                }
                var video = new VdoPlayer({ // eslint-disable-line
                    otp: otp.value,
                    playbackInfo: btoa(JSON.stringify({
                        videoId: videoId
                    })),
                    theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
                    // the container can be any DOM element on website
                    container: document.querySelector("#video"),
                });
            })
            return {otp, loadingState, fetchOtp}

        },
        created() {
            this.fetchOtp()
        }
    }
</script>
<style>

</style>